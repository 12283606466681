import JobZImage from "../jobz-img";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { publicUser } from "../../../globals/route-names";

function Header1({ _config }) {
  const [menuActive, setMenuActive] = useState(false);
  const menuRef = useRef(null);

  // Toggle sidebar menu
  function handleNavigationClick() {
    setMenuActive(!menuActive);
  }

  // Close sidebar when clicking on a menu item
  function handleMenuItemClick() {
    setMenuActive(false);
  }

  // Close sidebar when clicking outside of the menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuActive(false);
      }
    }

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <header
        className={
          "site-header " +
          _config.style +
          " mobile-sider-drawer-menu " +
          (menuActive ? "active" : "")
        }
        ref={menuRef} // Attach ref to the header
      >
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container-fluid clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={publicUser.INITIAL}>
                    {_config.withBlackLogo ? (
                      <JobZImage src="images/logo-12.png" alt="" />
                    ) : _config.withWhiteLogo ? (
                      <JobZImage src="images/logo-white.png" alt="" />
                    ) : _config.withLightLogo ? (
                      <>
                        <JobZImage
                          id="skin_header_logo_light"
                          src="images/logo-light-3.png"
                          alt=""
                          className="default-scroll-show"
                        />
                        <JobZImage
                          id="skin_header_logo"
                          src="images/logo-dark.png"
                          alt=""
                          className="on-scroll-show"
                        />
                      </>
                    ) : (
                      <JobZImage
                        id="skin_header_logo"
                        src="images/logo-dark.png"
                        alt=""
                      />
                    )}
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
                onClick={handleNavigationClick}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* MAIN Nav */}
              <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                <ul className=" nav navbar-nav">
                  <li className="has-child">
                    <NavLink to="/" onClick={handleMenuItemClick}>Home</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to={publicUser.jobs.LIST} onClick={handleMenuItemClick}>Jobs</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to={publicUser.pages.ABOUT} onClick={handleMenuItemClick}>About Us</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to={publicUser.pages.CONTACT} onClick={handleMenuItemClick}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>
              {/* Header Right Section*/}
              <div className="extra-nav header-2-nav">
                <div className="extra-cell">
                  <div className="header-nav-btn-section">
                    <div className="twm-nav-btn-left">
                      <a className="twm-nav-sign-up" href="https://app.jobstartnow.com/register">
                        <i className="feather-log-in" /> Register
                      </a>
                    </div>
                    <div className="twm-nav-btn-right">
                      <a className="twm-nav-post-a-job" href="https://app.jobstartnow.com/login">
                        <i className="feather-log-in" /> Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SITE Search */}
          <div id="search">
            <span className="close" />
            <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
              <input className="form-control" name="q" type="search" placeholder="Type to search" />
              <span className="input-group-append">
                <button type="button" className="search-btn">
                  <i className="fa fa-paper-plane" />
                </button>
              </span>
            </form>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header1;
