import JobZImage from "../../../../common/jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import SectionPagination from "../common/section-pagination";

function SectionJobsGrid() {
    return (
        <>
            <div className="row">
                {/*Block one*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Web Designer , Developer</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block two*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">15 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Rolling Stock Technician</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$7 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block three*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">6 Month ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">IT Department Manager</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Four*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">2 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Art Production Specialist</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1800 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Five*/}
                <div className="masonry-item col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic5.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Recreation &amp; Fitness Worker</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Six*/}
                <div className="masonry-item col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Web Designer , Developer</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$19 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Seven*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Web Designer , Developer</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Eight*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">15 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Rolling Stock Technician</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$7 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block Nine*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">6 Month ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">IT Department Manager</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                {/*Block ten*/}
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">2 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Art Production Specialist</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1800 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <SectionPagination />
        </>
    )
}

export default SectionJobsGrid;