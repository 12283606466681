import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionJobsList from "../../sections/jobs/section-jobs-list";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";

function JobsListPage() {
    const _filterConfig = {
        prefix: "Showing",
        type: "jobs",
        total: "2,150",
        showRange: false,
        showingUpto: "",
    };

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {/*Filter Short By*/}
                            <div className="twm-bnr-search-bar mb-5">
                                <form>
                                    <div className="row">
                                        {/*Title*/}
                                        <div className="form-group col-xl-3 col-lg-6 col-md-6">
                                            <label>What</label>
                                            <select
                                                className="wt-search-bar-select selectpicker"
                                                data-live-search="true"
                                                title=""
                                                id="j-Job_Title"
                                                data-bv-field="size"
                                            >
                                                <option disabled value="">
                                                    Select Category
                                                </option>
                                                <option selected>
                                                    Job Title
                                                </option>
                                                <option>Web Designer</option>
                                                <option>Developer</option>
                                                <option>Acountant</option>
                                            </select>
                                        </div>
                                        {/*All Category*/}
                                        <div className="form-group col-xl-4 col-lg-6 col-md-6">
                                            <label>Type</label>
                                            <select
                                                className="wt-search-bar-select selectpicker"
                                                data-live-search="true"
                                                title=""
                                                id="j-All_Category"
                                                data-bv-field="size"
                                            >
                                                <option disabled value="">
                                                    Select Category
                                                </option>
                                                <option selected>
                                                    All Category
                                                </option>
                                                <option>Web Designer</option>
                                                <option>Developer</option>
                                                <option>Acountant</option>
                                            </select>
                                        </div>
                                        {/*Location*/}
                                        <div className="form-group col-xl-3 col-lg-6 col-md-6">
                                            <label>Location</label>
                                            <div className="twm-inputicon-box">
                                                <input
                                                    name="username"
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    placeholder="Location"
                                                />
                                                <i className="twm-input-icon fas fa-map-marker-alt" />
                                            </div>
                                        </div>
                                        {/*Find job btn*/}
                                        <div className="form-group col-xl-2 col-lg-6 col-md-6">
                                            <button
                                                type="button"
                                                className="site-button"
                                            >
                                                Find Job
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 order-lg-1 order-1">
                            <SectionRecordsFilter _config={_filterConfig} />
                            <SectionJobsList />
                        </div>
                        <div className="col-lg-3 col-md-3 order-1 order-lg-12">
                            <img
                                src="
                            ./assets/images/sidebar.png"
                                alt="banner"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobsListPage;
