function BuyNowFloatingMenu() {
    return (
        <>
            <a href="https://themeforest.net/item/job-board-html-template/38681133" className="buy-now-btn">
                <i className="fas fa-cart-plus" />Buy NoW
            </a>
        </>
    )
}

export default BuyNowFloatingMenu;